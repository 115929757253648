import React, { useState } from 'react';
import '../../styles/form.css';


const contactInfo = [
  {
    icon:'ri-map-pin-line',
    label:'Address:',
    value:'1st floor, Sanskruti Building, above HDFC bank, Kadam Wasti, Loni Kalbhor, pune, Maharashtra 412201'
  },
  {
    icon:'ri-phone-line',
    label:'Phone:',
    value:'+91 88477-96088'
  },
  {
    icon:'ri-send-plane-fill',
    label:'Email:',
    value:'support@theprodigyy.com'
  },
  {
    icon:'ri-earth-line',
    label:'Website:',
    value:'theprodigyy.com'
  },
]

const Form = () => {
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    email: '',
    organization: '',
    city: '',
  });

  const [validationStatus, setValidationStatus] = useState({
    name: true,
    contact: true,
    email: true,
    organization: true,
    city: true,
  });

   // Handle input changes
   const handleInputChange = (e) => {
    const { name, value } = e.target;


    setFormData({ ...formData, [name]: value });

    const validationFunctions = {
      name: validateName,
      contact: validateContact,
      email: validateEmail,
      organization: validateOrganization,
      city: validateCity,
    };

    setValidationStatus((prevStatus) => ({
      ...prevStatus,
      [name]: validationFunctions[name](value),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('http://theprodigyy.com/store-form-data.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `name=${formData.name}&contact=${formData.contact}&email=${formData.email}&organization=${formData.organization}&city=${formData.city}`,
      });

      if (response.ok) {
        // Display a success message to the user
        alert('Thank you for reaching out to us! Our team will respond to your inquiry soon.');

        // Optionally, you can reset the form fields
        setFormData({
          name: '',
          contact: '',
          email: '',
          organization: '',
          city: '',
        });
      } else {
        // Display an error message to the user
        alert('Failed to submit the form. Please try again later.');
      }
    } catch (error) {
      console.error('Error submitting the form:', error);
      // Display an error message to the user
      alert('Failed to submit the form. Please try again later.');
    }
  };


//================ Validation ==================================
    
    const validateName = (value) => /^[a-zA-Z\s]+$/.test(value) && value.trim() !== 'Name should not be empty'; 

    const validateContact = (value) => /^\d{10}$/.test(value) && value.trim() !== 'Mobile should be 10 digit';
  
    const validateEmail = (value) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
  
    const validateOrganization = (value) => /^[a-zA-Z\s]+$/.test(value) && value.trim() !== 'Organization should not be empty';
  
    const validateCity = (value) => /^[a-zA-Z\s]+$/.test(value) && value.trim() !== 'City should not be empty';

//==============================================================

        



// ==================================================================


  return <section id='form'>
    <div className="form-container">

      <h6 className="subtitle">Let's get in touch</h6>
      <h2>Let's talk <span className='highlight'>about everything </span></h2>



      <div className="form__sections">
        <form onSubmit={handleSubmit}>
          <div className="form__content">
            <h3>It's easy. <br />Just fill the form.</h3>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                style={{ borderColor: validationStatus.name ? 'initial' : 'red' }}
              />
              {!validationStatus.name && <p style={{ color: 'black' }}>Please enter a valid name.</p>}
            </div>

            <div className="form-group">
              <label htmlFor="contact">Phone:</label>
              <input
                type="text"
                id="contact"
                name="contact"
                value={formData.contact}
                onChange={handleInputChange}
                style={{ borderColor: validationStatus.contact ? 'initial' : 'red' }}
              />
              {!validationStatus.contact && <p style={{ color: 'black' }}>*Please enter 10-digit number.</p>}
            </div>

            <div className="form-group">
            <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
                style={{ borderColor: validationStatus.email ? 'initial' : 'red' }}
              />
              {!validationStatus.email && <p style={{ color: 'black' }}>Please enter a valid email address.</p>}
            </div>

            <div className="form-group">
              <label htmlFor="organization">Organization:</label>
              <input
                type="text"
                id="organization"
                name="organization"
                value={formData.organization}
                onChange={handleInputChange}
                style={{ borderColor: validationStatus.organization ? 'initial' : 'red' }}
              />
               {!validationStatus.organization && <p style={{ color: 'black' }}>Please enter a valid organization.</p>}
            </div>

            <div className="form-group">
              <label htmlFor="city">City:</label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                style={{ borderColor: validationStatus.city ? 'initial' : 'red' }}
              />
              {!validationStatus.city && <p style={{ color: 'black' }}>Please enter a valid city.</p>}
            </div>
            <button className='sub__btn' type="submit" onClick={handleSubmit}>Submit</button>
          
          </div>
          
        </form>
        <div className="form__info">
              <h3>Contact us.</h3>
              {
                contactInfo.map((item,index)=>(
                  <div className="details" key={index}>
                    <span className='icon'><i class={item.icon}></i></span>
                    <h5 className='highlight'>{item.label}</h5>
                    <p className="value">{item.value}</p>
                  </div>
                ))
              }
        </div>
      </div>
    </div>
  </section>
}

export default Form;