import { Link } from 'react-router-dom';
import React, { useRef, useEffect } from "react";
import './header.css';
import logo1 from '../../images/logo1.png';

const nav__links = [
  {
    icon: 'ri-home-line',
    path: '#hero',
    display: 'Home'
  },
  {
    icon: 'ri-task-line',
    path: '#projects',
    display: 'Projects'
  },
  {
    icon: 'ri-shake-hands-line',
    path: '#services',
    display: 'Services'
  },
  {
    icon: 'ri-team-line',
    path: '#about',
    display: 'About'
  },
  {
    icon: 'ri-chat-3-line',
    path: '#blog',
    display: 'Blog'
  },
];

const Header = ({ theme, toggleTheme }) => {
  const headerRef = useRef(null);
  const menuRef = useRef(null);

  const headerFunc = () => {
    if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
      headerRef.current.classList.add('header__shrink');
    } else {
      headerRef.current.classList.remove('header__shrink');
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', headerFunc);
    return () => window.removeEventListener('scroll', headerFunc);
  }, []);

  const scrollToSection = (e, path) => {
    e.preventDefault();
    const target = document.querySelector(path);
    if (target) {
      window.scrollTo({
        top: target.offsetTop - 80,
        behavior: 'smooth',
      });
    }
  };

  const toggleMenu = () => menuRef.current.classList.toggle('menu__active');

  return (
    <header className="header" ref={headerRef}>
      <div className="container">
        <div className="nav__wrapper">
          <div className="logo">
            <img src={logo1} alt="" />
          </div>

          <div className="navigation" ref={menuRef} onClick={toggleMenu}>
            <ul className="menu">
              {nav__links.map((item, index) => (
                <li className="menu__item" key={index}>
                  <span className='menu__icon-items'><i className={item.icon}></i></span>
                  {item.path.startsWith('/') ? (
                    <Link to={item.path} className="menu__link">
                      {item.display}
                    </Link>
                  ) : (
                    <a href={item.path} onClick={(e) => scrollToSection(e, item.path)} className="menu__link">
                      {item.display}
                    </a>
                  )}
                </li>
              ))}
            </ul>
          </div>

          <div className="light__mode">
            <span onClick={toggleTheme}>
              {theme === 'light-theme' ? (
                <span>
                  <i className="ri-moon-line"></i>Dark
                </span>
              ) : (
                <span>
                  <i className="ri-sun-line"></i>Light
                </span>
              )}
            </span>
          </div>
          <span className="mobile__menu" onClick={toggleMenu}>
            <i className="ri-menu-line"></i>
          </span>
        </div>
      </div>
    </header>
  );
}

export default Header;
