import React from 'react';

import '../../styles/about.css';
import aboutImg from '../../images/aboutus.jpg';

const chooseData = [
    {
        icon: 'ri-phone-line',
        title: 'Tele-calling BPO services',
        desc: 'We offer Tele-calling BPO services that provide a human touch to your customer interactions. Our team of skilled professionals is well-equipped to handle customer inquiries, provide support, and assist in resolving issues. With our tele-calling services, you can enhance customer satisfaction, streamline communication, and build stronger relationships with your clientele.'
    },
    {
        icon: 'ri-admin-line',
        title: 'Lead Generation',
        desc: "One of the core pillars of our service offerings is Lead Generation. We specialize in identifying and connecting with potential customers who have shown interest in your products or services. Our lead generation strategies are designed to expand your customer base and increase sales opportunities. With Prodigyy's lead generation services, you can expect a steady flow of qualified leads that can be nurtured into valuable business relationships."
    },
    {
        icon: 'ri-team-line',
        title: 'Dedicated team',
        desc: "We understand that success in the ever-evolving world of marketing demands unwavering commitment and a laser-sharp focus. That's why we offer our Dedicated Team services, a powerful solution designed to take your marketing strategies to the next level."
    },
    // {
    //     icon: 'ri-customer-service-2-line',
    //     title: '24/7 Customer Support',
    //     desc: "We understand that exceptional customer service is at the heart of successful marketing. That's why we're proud to offer round-the-clock support to our valued clients. Our 24/7 customer service is designed to ensure that your needs are met, questions are answered, and issues are resolved promptly, no matter the time of day."
    // },
]

const About = () => {
    return <section id="about">
        <div className="container">
            <div className="about__wrapper">
                <div className="about__content">
                    <h6 className="subtitle">Why choose us</h6>
                    <h2>Specialized in avoiding clients on</h2>
                    <h2 className="highlight">Financial Challenges</h2>
                    <p className="description about__content-desc">
                        At Prodigyy, we believe in close collaboration with our clients. We work hand-in-hand to understand your unique goals and tailor our strategies to achieve them.
                    </p>
                    <div className='choose__item-wrapper'>
                        {
                            chooseData.map((item,index)=>(
                                <div className="choose__us-item">
                                    <span className="choose__us-icon">
                                        <i class={item.icon}></i>
                                    </span>
                                <div>

                                <h4 className= "choose__us-title">
                                    {item.title}
                                </h4>

                                <p className="description indesc">
                                    {item.desc}
                                </p>

                                </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
                <div className="about__img">
                    <img src={aboutImg} alt="" />
                </div>
            </div>
        </div>
    </section>
}

export default About;
