import React from 'react';
import '../../styles/hero.css';
//import form from './Form';
import heroDarkImg from '../../images/dark__hero.png';
import lightImg from '../../images/light-hero-bg.png';


const Hero = ({theme}) => {

    return <section className='hero__section' id='hero'>
        <div className="container">
            <div className="hero__wrapper">
                <div className="hero__content">
                    <div>
                        <h2>Unleash Your Brand's </h2>
                        <h2 className="highlight">Potential with Prodigyy</h2>
                    </div>
                    <p className="description">
                    At Prodigyy, we believe in harnessing global talent and weaving
unparalleled creativity into every project. Together, we embark on a
transformative adventure where imagination and reality merge, and your
brand's potential knows no bounds.
                    </p>
                    <div className="hero__btns" >
                        <a href="#form" className="primary__btn get__start">Get Started Now</a>
                        <button className="secondary__btn">Discover More</button>
                    </div>
                </div>
                <div className="hero__img">
                    <img src={ theme==='light-theme' ? lightImg : heroDarkImg} alt="hero-img" />
                </div>
            </div>
        </div>
    </section>
}

export default Hero;