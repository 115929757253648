import React, { useState, useEffect } from 'react';
import '../../styles/tableComponent.css';
import { useNavigate } from 'react-router-dom';

const ShowTable = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkAuthentication = async () => {
      try {
        const response = await fetch('https://theprodigyy.com/checkLogin.php');
        const data = await response.json();

        if (data.authenticated) {
          setAuthenticated(true);
          // Fetch data only if authenticated
          fetchData();
        } else {
          // If not authenticated, navigate to the login page
          navigate('/login');
        }
      } catch (error) {
        console.error('Error checking authentication:', error.message);
      }
    };

    checkAuthentication();
  }, [navigate]);

  const fetchData = async () => {
    try {
      const response = await fetch('https://theprodigyy.com/show-data.php');
      if (response.ok) {
        const data = await response.json();
        setFormData(data);
        setLoading(false);
      } else {
        console.error('Error fetching form data:', response.statusText);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error fetching form data:', error.message);
      setLoading(false);
    }
  };

  if (loading) {
    return <p>Loading...</p>; // You can customize the loading indicator
  }

  return (
    <div id='show-table' className='whole__container'>
      <h2>Show Form Data Table</h2>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Contact</th>
            <th>Email</th>
            <th>Organization</th>
            <th>City</th>
            {/* Add more columns based on your form data structure */}
          </tr>
        </thead>
        <tbody>
          {formData.map((entry) => (
            <tr key={entry.id}>
              <td>{entry.name}</td>
              <td>{entry.contact}</td>
              <td>{entry.email}</td>
              <td>{entry.organization}</td>
              <td>{entry.city}</td>
              {/* Add more cells based on your form data structure */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ShowTable;
