import React from 'react';
import '../../styles/team.css';
import team01 from '../../images/t1.png';
import team02 from '../../images/t2.png';
import team03 from '../../images/t3.png';
import team04 from '../../images/t4.png';
import team05 from '../../images/t5.png';

const teamMembers = [
    {
        imgUrl: team01,
        name: 'Prasad Adhav',
        position: 'Founder & CEO',
        insta: '#',
        linkedIn: 'https://www.linkedin.com/in/prasad-adhav-8413712aa/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app'
    },
    {
        imgUrl: team02,
        name: 'Dipti Prasad',
        position: 'Finanace Head',
        insta: '#',
        linkedIn: '#'
    },
    {
        imgUrl: team03,
        name: 'Prajwal',
        position: 'CTO',
        insta: '#',
        linkedIn: 'https://www.linkedin.com/in/prajwal-konde/'
    },
    {
        imgUrl: team04,
        name: 'Prithvi',
        position: 'Business Head',
        insta: '#',
        linkedIn: '#'
    },
    {
        imgUrl: team05,
        name: 'Nilesh Kulkarni',
        position: 'Admin Head',
        insta: '#',
        linkedIn: '#'
    },
    
]

const Team = () => {
    return <section className='our__team'>
        <div className="container">
            <div className="team__content">
                <h6 className="subtitle">Our Team</h6>
                <h2>Meet with <span className="highlight">our team</span></h2>
            </div>

            <div className="team__wrapper">
                {
                    teamMembers.map((item,index)=>(
                        <div className="team__item" key={index}>
                            <div className="team__img">
                                <img src={item.imgUrl} alt="" />
                            </div>
                            <div className="team__details">
                                <h4>{item.name}</h4>
                                <p className="description">{item.position}</p>

                                <div className="team__member-social">
                                    <a href={item.linkedIn}><i class="ri-linkedin-line"></i></a>
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    </section>
}

export default Team;