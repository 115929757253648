import React from 'react';
import '../Footer/footer.css';

const quickLinks01 = [
    {
        path: '#',
        display: 'Marketing'
    },
    {
        path: '#',
        display: 'Analytics'
    },
    {
        path: '#',
        display: 'Commerce'
    },
];

const quickLinks02 = [
    {
        path: '/pricing',
        display: 'Pricing'
    },
    {
        path: '#',
        display: 'Documentation'
    },
    {
        path: '#',
        display: 'Guides'
    },
];

const quickLinks03 = [
    {
        path: '/login', 
        display: 'Login', 
    },
    {
        path: 'Careers',
        display: 'Careers'
    },
    {
        path: '#blog',
        display: 'Blog'
    },
]

const Footer = () => {

    const year = new Date().getFullYear()

    return <footer className="footer">
        <div className="container">
            <div className="footer__wrapper">
                <div className="footer__logo">
                    <h2>Prodigyy</h2>
                    <p className="description">Grow with us</p>

                    <p className="small__text description">
                        Ready to embark on a transformative journey with Prodigyy? 
                        Contact us today to discuss how we can tailor our services to your unique needs.
                    </p>
                </div>
{/* 1st Column */}
                <div className="footer__quick-links">
                    <h3 className="quick__links-title">Solutions</h3>
                    <ul className="quick__links">
                        {
                            quickLinks01.map((item,index)=>(
                                <li className="quick__link-item" key={index}>
                                    <a href={item.path}>
                                        {item.display}
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
{/* 2nd Column */}
                <div className="footer__quick-links">
                    <h3 className="quick__links-title">Support</h3>
                    <ul className="quick__links">
                        {
                            quickLinks02.map((item,index)=>(
                                <li className="quick__link-item" key={index}>
                                    <a href={item.path}>
                                        {item.display}
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>
{/* 3rd Column */}
                <div className="footer__quick-links">
                    <h3 className="quick__links-title">Company</h3>
                    <ul className="quick__links">
                        {
                            quickLinks03.map((item,index)=>(
                                <li className="quick__link-item" key={index}>
                                    <a href={item.path}>
                                        {item.display}
                                    </a>
                                </li>
                            ))
                        }
                    </ul>
                </div>

            </div>
            <p className="description copyright">
                    Copyright {year}, developed by Prajwal Konde. All rights reserved.
            </p>
        </div>
    </footer>
}

export default Footer;