import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Hero from './components/UI/Hero';
import Counter from './components/UI/Counter';
import Services from './components/UI/Services';
import About from './components/UI/About';
import Team from './components/UI/Team';
import Blog from './components/UI/Blog';
import Testimonial from './components/UI/Testimonial';
import Newsletter from './components/UI/Newsletter';
import Footer from './components/Footer/Footer';
import Form from './components/UI/Form';


import 'remixicon/fonts/remixicon.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import App from './App';
import Careers from './pages/Careers';
import Login from './components/UI/Login';
import Table from './components/UI/ShowTable';
import Packages from './components/UI/Packages';
//import TableComponent from './components/UI/TableComponent';

ReactDOM.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/" element={<Hero />} />
      <Route path="/counter" element={<Counter/>} />
      <Route path="/services" element={<Services/>} />
      <Route path="/about" element={<About/>} />
      <Route path="/team" element={<Team/>} />
      <Route path="/blog" element={<Blog/>} />
      <Route path="/form" element={<Form/>} />
      <Route path="/testimonial" element={<Testimonial/>} />
      <Route path="/newsletter" element={<Newsletter/>} />
      <Route path="/footer" element={<Footer />} />
      <Route path="/careers" element={<Careers />} />
      <Route path="/login" element={<Login />} />
      <Route path="/table" element={<Table />} />
      <Route path="/pricing" element={<Packages />} />
    </Routes>
  </BrowserRouter>,
  document.getElementById('root')
);
