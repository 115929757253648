// PricingPackages.js

import React from 'react';
import '../../styles/packages.css';

const packagesData = [
    {
      title: 'Small Scale Business',
      items: [
        'Webpage Development',
        'Social media growth',
        'Google Reviews',
        'Influencer video (1)',
        'Peak season Social media',
        'Pamphlet through news Paper',
      ],
      price: '1,00,000',
      addons: [
        'Lead Generation',
        'Tele-Calling',
        'Meta Ad mgmt. (Facebook & Instagram)',
        'Google ad mgmt.',
        'Search Engine Opt',
        'PPC Ads',
        'Market research and analytics',
        'Content creation',
        'Brand Ambassador',
        'Email & SMS Marketing',
        'Business consulting calls',
        'Web-Traffic',
        'B2B',
        'Hoardings and Display',
        'Mascot management',
        'Pamphlets Distribution',
        'Info-Graphic video',
        'TV Commercial',
        'Radio Broadcasting',
        'App Development',
      ],
    },
    {
      title: 'Medium Scale Business',
      items: [
        'Webpage Development',
        'Social media growth',
        'Google Reviews',
        'Influencer video (2)',
        'Peak season Social media',
        'Pamphlet through news Paper',
        'Market research and analytics',
        'Content creation',
        'Search Engine Opt',
        'Web-Traffic',
        'Pamphlets Distribution',
        'Email & SMS Marketing',
      ],
      price: '4,00,000',
      addons: [
        'Lead Generation',
        'Tele-Calling',
        'Meta Ad mgmt. (Facebook & Instagram)',
        'Google ad mgmt.',
        'Brand Ambassador',
        'PPC Ads',
        'Info-Graphic video',
        'TV Commercial',
        'Radio Broadcasting',
        'App Development',
        'Business consulting calls',
        'Mascot management',
        'B2B',
        'Hoardings and Display',
      ],
    },
    {
      title: 'Large Scale Business',
      items: [
        'Webpage Development',
        'Social media growth',
        'Google Reviews',
        'Influencer video (3)',
        'Peak season Social media',
        'Pamphlet through news Paper',
        'Market research and analytics',
        'Content creation',
        'Search Engine Opt',
        'Web-Traffic',
        'Pamphlets Distribution',
        'Meta Ad mgmt. (Facebook & Instagram)',
        'Lead Generation',
        'Tele-Calling',
        'Mascot management',
        'Email & SMS Marketing',
        'Info-Graphic video (1)',
        'Business consulting calls',
        'PPC Ads',
        'Radio Broadcasting',
      ],
      price: '7,00,000',
      addons: [
        'App Development',
        'Business consulting calls',
        'TV Commercial',
        'Google ad mgmt.',
        'Brand Ambassador',
        'B2B',
        'Hoardings and Display',
      ],
    },
  ];
  
  const Packages = () => {

    const redirectToForm = () => {
      window.location.href = 'https://theprodigyy.com/#form';
    };

    return (
        <div className="pricing-container">
          {packagesData.map((packageItem, index) => (
            <div className="package-column" key={index}>
              {/* Group One: Title, Items, and Price */}
              <div className="group-one">
                <h2>{packageItem.title}</h2>
                <ul>
                  {packageItem.items.map((item, itemIndex) => (
                    <li key={itemIndex}>{item}</li>
                  ))}
                </ul>
                <button className='price__btn' onClick={redirectToForm}>Price: {packageItem.price}</button>
              </div>
    
              {/* Group Two: Add-ons */}
              <div className="group-two">
                <h3>Add-ons</h3>
                <ul>
                  {packageItem.addons.map((addon, addonIndex) => (
                    <li key={addonIndex}>{addon}</li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </div>
      );
  };
  
  export default Packages;