import React from 'react';
import '../../styles/services.css'

const serviceData = [
    {
        icon: 'ri-shake-hands-line',
        title: 'Physical Enchantment',
        desc: "Our physical marketing strategies conjure moments of awe, forging profound connections between you and your audience."
    },
    {
        icon: 'ri-rocket-line',
        title: 'Digital Mastery',
        desc: "With a profound understanding of your unique requirements and the latest cutting-edge techniques, we propel your business to the pinnacle it rightfully deserves."
    },
    {
        icon: 'ri-shopping-bag-2-line',
        title: 'Brand Alchemy',
        desc: "We craft stories that endure, forging emotional bonds between your brand and your Indian audience."
    },
    {
        icon: 'ri-wechat-line',
        title: 'Conversion Magic',
        desc: "We are here to infuse vitality into your conversions, rejuvenating your Indian business and revitalizing your return on investment."
    },
    {
        icon: 'ri-user-star-line',
        title: 'Influencer Partnerships',
        desc: "We build partnerships that resonate with your audience, enhancing your brand's credibility and taking your success to a deeply human level."
    },
    
]

const Services = () => {
    return <section id="services">
        <div className="container">
            <div className="service__top-content">
                <h6 className="subtitle">Our Services</h6>
                <h2>ALL MARKETING NEED FULFILLED</h2>
                <h2 className="highlight">UNDER ONE ROOF!</h2>
            </div>
            <div className="service__item-wrapper">
                {
                    serviceData.map((item,index)=>(
                        <div className="service__item" key={index}>
                            <span className="service__icon">
                                <i class={item.icon}></i>
                            </span>
                            <h3 className="service__title">{item.title}</h3>
                            <p className="description">
                               {item.desc}
                            </p>
                        </div>
                    ))
                }
            </div>
        </div>
    </section>
}

export default Services;