import React, { useState } from 'react';
import '../../styles/login.css'

const Login = ({ setLoggedIn }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
  
    // Validate that username and password are not empty
    if (!username.trim() || !password.trim()) {
      console.error('Username and password are required.');
      return;
    }
  
    try {
      const response = await fetch('https://theprodigyy.com/login.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
      });
  
      if (response.ok) {
        const data = await response.text();
        if (data === 'success') {
          // Redirect to the table page only if the server response is 'success'
          window.location.href = 'https://theprodigyy.com/table';
        } else {
          console.error('Login failed');
        }
      } else {
        console.error('Login failed');
      }
    } catch (error) {
      console.error('Error during login:', error);
    }
  };
  

  return (
    <div className="login-container">
    <form onSubmit={handleLogin}>
      <div className="login__wrapper">
      <label>
        Username:
        </label>
        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
      
      <br />
      <label>
        Password:
        </label>
        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
      
      <br />
      
      <button type="submit">Login</button>
      </div>
    </form>
    </div>
  );
};

export default Login;
